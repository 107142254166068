import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkGrid from '../../components/terms/link-grid'
import DomainRegistrationContent from '../../components/terms/domain-registration'

const DomainRegistration = ({ location }) => {
  return (
    <Layout>
      <SEO
        title='Domain Registration'
        pageType='Domain Registration'
        flowType='none'
      />
      {typeof window !== 'undefined' && (
        <LinkGrid pathname={location.pathname} />
      )}
      <DomainRegistrationContent />
    </Layout>
  )
}

export default DomainRegistration
